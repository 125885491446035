import React from 'react';
import { Box, Text, Link, HStack } from '@chakra-ui/react';
import Button from './Button';

const CookieBanner = ({ handleAccept, handleReject, handleManage }: any) => {
  const lang = window.navigator.language;

  const translations = {
    en: {
      title: "We value your privacy",
      description:
        "We use cookies to offer useful features and measure performance to improve your experience. By clicking “Accept All”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. View our Privacy Policy for more information.",
      privacyPolicy: "Privacy Policy",
      customize: "Customize settings",
      customizeSmall: "Customize",
      reject: "Reject All",
      accept: "Accept All",
    },
    ja: {
      title: "プライバシーについて",
      description:
        "当社では、サービスの向上とユーザー体験改善のため、Cookieを使用しています。「すべて許可」をクリックすることで、Cookieの使用に同意いただいたものとみなします。詳しくはプライバシーポリシーをご覧ください。",
      privacyPolicy: "プライバシーポリシー",
      customize: "設定を管理",
      customizeSmall: "設定を管理",
      reject: "すべて拒否",
      accept: "すべて許可",
    },
  };

  const t = translations[lang]  || translations["en"];
  const parts = t.description.split(t.privacyPolicy);


  return (
    <Box
      position="fixed"
      bottom={0}
      // width={{ base: "375px",md: "100%" }}
      width={"100%"}
      bg="#1A1A1A"
      color="white"
      // p={6}
      padding={6}
      boxShadow="lg"
      zIndex="99999999999999"
      height={{ sm: 'fit-content', md: '160px' }}
    >
      <Box maxW="977px">
        <Text fontSize="20px" fontWeight="semibold" mb={4} lineHeight="28px" fontFamily="Montserrat" letterSpacing="-0.01em">
          {t.title}
        </Text>
      </Box>
      <HStack justifyContent="space-between" flexDirection={{ base: 'column', md: 'row' }} gap={{ base: '16px', md: '0' }}>
        <Box width={{ base: "100%", md: "977px" }} height={{ base: "fit-content", md: "48px" }}>
        <Text fontSize="14px" fontWeight="normal" fontFamily="lato" lineHeight="24px">
    {parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < parts.length - 1 && (
          <Link
            href={
              lang === "ja"
                ? "https://hello.whereness.io/ja/privacy-policy"
                : "https://hello.whereness.io/en/privacy-policy"
            }
            isExternal
            textDecoration="underline"
          >
            {t.privacyPolicy}
          </Link>
        )}
      </React.Fragment>
    ))}
  </Text>
        </Box>
        <HStack height="48px" width={{ base: "311px", md: "auto" }} spacing={{ base: "4px", md: '0' }}>
          <Box width={{ base: '101px', md: lang === 'ja' ? '110px' : '179px', }} px={1} py={1.5} height="48px">
            <Button
              sx={{
                '&::before': {
                  content: 'none',
                  display: 'none',
                },
              }}
              color="transparent"
              onClick={handleManage}
              width={{ base: '93px', md: lang === 'ja' ? '102px' : '171px', }}
              px={3}
              py={2.5}
              height="36px"
              border="none"
              bg="transparent"
              _hover={{ 
                color: '#B0D8FF',
                backgroundColor: '#ffffff1a',
               }}
              _focus={{ boxShadow: 'none' }}
            >
              <Text display={{ base: 'none', md: 'block' }} color="rgba(142, 194, 245, 1)" fontFamily="Montserrat, sans-serif"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center">
                {t.customize}
              </Text>
              <Text display={{ base: 'block', md: 'none' }} color="rgba(142, 194, 245, 1)" fontFamily="Montserrat, sans-serif"
                fontWeight="600"
                fontSize="14px"
                lineHeight="20px"
                textAlign="center">
                {t.customizeSmall}
              </Text>
            </Button>
          </Box>
          <Box
            width={{ base: '101px', md: lang === 'ja' ? '109px' : '112px', }} px={1} py={1.5}
            height="48px"
          >
            <Button
              color="solid"
              onClick={handleReject}
              width={{ base: '101px', md: lang === 'ja' ? '101px' : '104px', }}
              height="36px"
              px={3}
              py={2.5}
              bg="#2067E3"
              borderRadius="16px"
              fontFamily="Montserrat, sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              textAlign="center"
              _hover={{ bg: '#4A8DF2' }}
              _focus={{ boxShadow: 'none' }}
              sx={{
                '&::before': {
                  content: 'none',
                  display: 'none',
                },
              }}
            >
              {t.reject}
            </Button>
          </Box>
          <Box
            width={{ base: '101px', md: '112px' }} px={1} py={1.5}
            height="48px"
          >
            <Button
              color="solid"
              onClick={handleAccept}
              width={{ base: '101px', md: lang === 'ja' ? '101px' : '104px' }}
              height="36px"
              bg="#2067E3"
              px={3}
              py={2.5}
              borderRadius="16px"
              fontFamily="Montserrat, sans-serif"
              fontWeight="600"
              fontSize="14px"
              lineHeight="20px"
              textAlign="center"
              _hover={{ bg: '#4A8DF2' }}
              _focus={{ boxShadow: 'none' }}
              sx={{
                '&::before': {
                  content: 'none',
                  display: 'none',
                },
              }}
            >
              {t.accept}
            </Button>
          </Box>
        </HStack>
      </HStack>
    </Box>
  );
};

export default CookieBanner;
