import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, Divider, Button } from '@chakra-ui/react';
import CustomSwitch from './CustomSwitch';

const CookieSettings = ({ setIsManageCookieBannerOpen, setIsCookieBannerOpen }) => {
  const [analytics, setAnalytics] = useState(false);

  const lang = window.navigator.language;

  const translations = {
    en: {
      settingsTitle: "Cookie Settings",
      strictlyNecessary: "Strictly Necessary",
      strictlyNecessaryDesc: "Cookies required to enable basic website functionality.",
      alwaysActive: "Always active",
      analytics: "Analytics",
      analyticsDesc: "Cookies helping understand how this website performs, how visitors interact with the site, and whether there may be technical issues.",
      saveSettings: "Save Settings",
    },
    ja: {
      settingsTitle: "Cookie設定",
      strictlyNecessary: "必須",
      strictlyNecessaryDesc: "サイトの基本的な機能を提供するために必要不可欠なCookieです。",
      alwaysActive: "常にアクティブ",
      analytics: "分析",
      analyticsDesc: "当サイトの動作状況やご利用状況の把握、技術的な問題の検出のために使用されるCookieです。",
      saveSettings: "設定を保存",
    },
  };

  const t = translations[lang] || translations['en'];

  useEffect(() => {
    const storedAnalytics = localStorage.getItem('analytics') === 'true';
    setAnalytics(storedAnalytics);
  }, []);

  const handleSave = () => {
    localStorage.setItem('analytics', analytics.toString());
    localStorage.setItem('strictlyNecessary', 'true');
    setIsCookieBannerOpen(false);
    setIsManageCookieBannerOpen(false);
  };

  return (
    <Box
      position="fixed"
      bottom="0"
      width="100%"
      bg="#1A1A1A"
      color="white"
      px={8}
      py={6}
      shadow="lg"
      zIndex="99999999999999"
      height={{ base: "380px", sm: "370px" ,md: "280px" }}
    >
      <Box height={{ md: "173px", sm:"240px", base: "260px" }}>
        <Box pb={4}>
          <Box height={{ md: "48px", base: "44px" }}>
            <Box pb={4}>
              <Text
                fontSize={{ base: '18px', lg: '20px' }}
                fontWeight="semibold"
                lineHeight="28px"
                color="rgba(255,255,255,0.97)"
                letterSpacing="-0.01em"
                fontFamily="Montserrat"
              >
                {t.settingsTitle}
              </Text>
            </Box>

            <Box display="flex" flexDirection="column" gap={2.5} w="full" h="auto">
              {/* Strictly Necessary */}
              <Flex
                gap={{ base: "16px", md: 6 }}
                // w={{ base: '311px', md: '864px' }}
                align={{ base: "left" }}
                flexDirection={{ base: 'column' }}
              >
                <Flex
                  justify="space-between"
                  align={{ md: "center", base: "baseline" }}
                   w="full"
                  gap={2.5}
                  // h={{ base: "96px", md: 12 }}
                >
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    lineHeight="24px"
                    color="rgba(255,255,255,0.97)"
                    w="145px"
                    letterSpacing="-0.01em"
                    fontFamily="Montserrat"
                  >
                    {t.strictlyNecessary}
                  </Text>
                  <Text
                  fontSize="12px"
                  fontWeight="semibold"
                  lineHeight="20px"
                  color="white"
                  w="84px"
                  textAlign={{ md: "center" }}
                  mt={{ base: -10, md: 0 }}
                  whiteSpace="nowrap"
                  fontFamily="Montserrat"
                >
                  {t.alwaysActive}
                </Text>
                 
                </Flex>
                <Text
                    fontSize="14px"
                    fontWeight="normal"
                    lineHeight="24px"
                    color="rgba(255,255,255,0.76)"
                    width={"fit-content"}
                    maxW={{ base: 'fit-content', md: '432px' }}
                  >
                    {t.strictlyNecessaryDesc}
                  </Text>
              </Flex>

              <Divider borderColor="rgba(255,255,255,0.24)" />

              {/* Analytics */}
              <Flex
               
                // gap={{ base: "16px" }}
                w="full"
                align={{ base: "left"}}
                flexDirection={{ base: 'column' }}
               
              >
                <Flex
                 justify="space-between"
                 align={{ md: "center", base: "baseline" }}
                 w="full"
                 gap={{ base: 2, md: 2.5 }}
                 h={{ base: "fit-content"}}
                >
                  <Text
                    fontSize="16px"
                    fontWeight="semibold"
                    lineHeight="24px"
                    color="rgba(255,255,255,0.97)"
                    letterSpacing="-0.01em"
                    w="145px"
                    fontFamily="Montserrat"
                  >
                    {t.analytics}
                  </Text>
                  <CustomSwitch isChecked={analytics} onChange={setAnalytics} />
                </Flex>
                <Flex
                    justify={"space-between"}
                    w="full"
                    alignItems={"center"}
                    
                  >
                  <Text
                      fontSize="14px"
                      fontWeight="normal"
                      lineHeight="24px"
                      color="rgba(255,255,255,0.76)"
                    >
                      {t.analyticsDesc}
                    </Text>
                    <Box display={{md: "block", base: "none"}} py={1.5} px={1} w={{ base: "311px", md: lang === 'ja' ? '110px' : '136px' }}>
                      <Button
                        bg="#2067E3"
                        color="white"
                        w={{ base: "311px", md: lang === 'ja' ? '102px' : '128px' }}
                        // _hover={{ bg: '#185abc' }}
                        _hover={{ bg: '#4A8DF2' }}
                        size="md"
                        onClick={handleSave}
                        px={3}
                        py={2.5}
                        height="36px"
                        sx={{
                          '&::before': {
                            content: 'none',
                            display: 'none',
                          },
                        }}
                      >
                        {t.saveSettings}
                      </Button>
                    </Box>
                </Flex>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Save Settings Button */}
      <Flex display={{md: 'none', base: "center"}} justify={{ md: "flex-end", base: "center" }} h="48px" w="full" mt={{ base: 6, md: 0 }}>
        <Box py={1.5} px={1} w={{ base: "311px", md: lang === 'ja' ? '110px' : '136px' }}>
          <Button
            bg="#2067E3"
            color="white"
            w={{ base: "311px", md: lang === 'ja' ? '102px' : '128px' }}
            // _hover={{ bg: '#185abc' }}
            _hover={{ bg: '#4A8DF2' }}
            size="md"
            onClick={handleSave}
            px={3}
            py={2.5}
            height="36px"
            sx={{
              '&::before': {
                content: 'none',
                display: 'none',
              },
            }}
          >
            {t.saveSettings}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default CookieSettings;
