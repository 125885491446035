import { Box, useColorModeValue } from "@chakra-ui/react";

function CustomSwitch({ isChecked, onChange }) {
  return (
    <Box
      as="label"
      display="inline-flex"
      alignItems="center"
      cursor="pointer"
      position="relative"
      width="44px"
      height="48px"
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
        style={{
          display: "none",
        }}
      />
      <Box
        width="36px"
        height="20px"
        background={isChecked ? "#2067E3" : "rgba(255, 255, 255, 0.24)"}
        borderRadius="full"
        position="relative"
        transition="all 0.3s"
        _after={{
          content: `""`,
          position: "absolute",
          top: "1px",
          left: isChecked ? "calc(100% - 18px - 1px)" : "1px",
          width: "18px",
          height: "18px",
          backgroundColor: isChecked ? "#292929" : "#1A1A1A",
          borderRadius: "full",
          transition: "all 0.3s",
        }}
      />
    </Box>
  );
}

export default CustomSwitch;
