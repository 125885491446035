import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'

interface PropsPopupButton extends ChakraButtonProps {
  icon?: ReactNode
  title?: string
  color?: 'transparent' | 'ghost' | 'solid'
  onClick?: React.MouseEventHandler<HTMLElement>
  children?: React.ReactNode
  classNameContainer?: string
  isTransition?: boolean
}

const Button = ({
  icon,
  title,
  color = 'solid',
  onClick,
  className,
  classNameContainer,
  isTransition = false,
  children,
  ...rest
}: PropsPopupButton): React.ReactElement => {
  const genColorButton = () => {
    switch (color) {
      case 'ghost':
        return { variant: 'ghost', colorScheme: 'blue' }
      case 'solid':
        return { variant: 'solid', colorScheme: 'blue' }
      case 'transparent':
        return { variant: 'outline', colorScheme: 'blue' }
      default:
        return {}
    }
  }

  return (
    <ChakraButton
      onClick={onClick}
      size="md"
      {...genColorButton()}
      {...rest}
    >
      {icon && <span>{icon}</span>}
      {title}
      {children}
    </ChakraButton>
  )
}

export default Button
