import React, { useEffect, useState } from "react"

import CookieBanner from "./CookieBanner"
import CookieSettings from "./CookieSettings"
import { Box } from "@chakra-ui/react"


const CookieShow = () => {
    const [isCookieBannerOpen, setIsCookieBannerOpen] = useState(false)
    const [isManageCookieOpen, setIsManageCookieBannerOpen] = useState(false)
  
    useEffect(() => {
      const analytics = localStorage.getItem('analytics')
      const strictlyNecessary = localStorage.getItem('strictlyNecessary')
  
      if (analytics !== null || strictlyNecessary !== null) {
        setIsCookieBannerOpen(false)
      } else {
        setIsCookieBannerOpen(true)
      }
    }, [])
  
    const handleAccept = () => {
      localStorage.setItem('analytics', 'true')
      localStorage.setItem('strictlyNecessary', 'true')
      setIsCookieBannerOpen(false)
    }
  
    const handleReject = () => {
      localStorage.setItem('analytics', 'false')
      localStorage.setItem('strictlyNecessary', 'true')
      setIsCookieBannerOpen(false)
    }
    const handleManage = () => {
      setIsManageCookieBannerOpen(true);
      setIsCookieBannerOpen(false);
    }
    return (
      <Box className="w-screen h-screen min-h-screen flex items-center justify-center flex-col bg-gray-800 overflow-hidden">
      {isCookieBannerOpen && (
        <CookieBanner
          handleAccept={handleAccept}
          handleReject={handleReject}
          handleManage={handleManage}
        />
      )}
      {isManageCookieOpen && (
        <CookieSettings
          setIsManageCookieBannerOpen={setIsManageCookieBannerOpen}
          setIsCookieBannerOpen={setIsCookieBannerOpen}
        />
      )}
    </Box>
    )
}

export default CookieShow


/*
    sohana Interview Points


*/
